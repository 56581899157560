@font-face {
  font-family: "Lexend";
  src: url("../fonts/Lexend-Light.woff2") format("woff2"),
    url("../fonts/Lexend-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lexend";
  src: url("../fonts/Lexend-Regular.woff2") format("woff2"),
    url("../fonts/Lexend-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lexend";
  src: url("../fonts/Lexend-Medium.woff2") format("woff2"),
    url("../fonts/Lexend-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
