:root {
  --base-font: #898989;
  --disabled-button-color: #898989;
  --upper-gradient: #00aac1;
  --lower-gradient: #01bfa7;
  --border-color: #cbcbcb;
  --background-color: #f5f5f5;
  --row-color: #F9F9F9;
  --row-border-color: #E2E2E2;
}
