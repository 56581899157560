// react-bootstrap
@import "bootstrap/dist/css/bootstrap.min.css";

// bootstrap variables
@import "./_fonts";
@import "./_typo";
@import "./variables";

// pages
@import "./layouts/_homepage";

// Reset
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Lexend";
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: var(--base-font);
}
