.h1 {
  font-family: "Lexend";
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
  color: var(--base-font);
}

.h2 {
  font-family: "Lexend";
  font-weight: 300;
  font-size: 32px;
  line-height: 40px;
  color: var(--base-font);
}

.h3 {
  font-family: "Lexend";
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
  color: var(--base-font);
}

.h4 {
  font-family: "Lexend";
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: var(--base-font);
}

.h5 {
  font-family: "Lexend";
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: var(--base-font);
}

.h6 {
  font-family: "Lexend";
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--base-font);
}

.p {
  font-family: "Lexend";
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: var(--base-font);
}

.span {
  font-family: "Lexend";
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: var(--base-font);
}

label {
  font-family: "Lexend";
  font-weight: 300;
  font-size: 10px;
  line-height: 10px;
  color: var(--base-font);
}
